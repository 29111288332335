<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import nativeToast from "native-toast";

export default {
  name: "App",
  data() {
    return {};
  },
  components: {},
  sockets: {
    connect() {
      this.setConnect(true);
    },
    disconnect() {
      this.setConnect(false);
    },
  },
  computed: mapGetters(["isAuthenticated", "getUser"]),
  methods: {
    ...mapActions([
      "fetchAssets",
      "fetchBalance",
      "setConnect",
      "setOnlines",
      "setBalance",
      "setMarket",
      "setBlock",
      "fetchMarket",
      "setInvest",
      "UsersConnected",
      "UsersDisconnected",
      "logout",
    ]),
    onlineUsers() {
      this.sockets.subscribe("users.joined", (data) => {
        this.UsersConnected(data);
      });

      this.sockets.subscribe("users.left", (data) => {
        this.UsersDisconnected(data);
      });

      this.sockets.subscribe("announce", (data) => {
        nativeToast({
          message: data.message,
          position: data.position,
          timeout: data.timeout,
          type: data.type,
        });
      });
    },
    exchange() {
      this.sockets.subscribe("market", (data) => {
        this.setMarket(data);
      });
    },
    investbox() {
      this.sockets.subscribe("investbox", (data) => {
        this.setInvest(data);
      });
    },
    block() {
      this.sockets.subscribe("blocks", (data) => {
        this.setBlock(data);
      });
    },
  },
  created() {
    this.setOnlines();
    this.onlineUsers();
    this.fetchAssets();
    this.fetchMarket();
    this.exchange();
    this.investbox();
    this.block();

    if (this.isAuthenticated) {
      this.fetchBalance();

      this.sockets.subscribe("user.balance." + this.getUser._id, (data) => {
        this.setBalance(data);
      });
    }
  },
};
</script>
