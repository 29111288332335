module.exports = {
  COMPANY: "SpaceImpact",
  VERSION: "1.0.7",
  POOL_ADDRESS: "0x907f2898b87115A53c8b33C4A390D65c929776c0",
  POOL_ADDRESS_ODD: "0xD2748F97Af748614E306E7185482d0d1afeCc93c",

  // SITE_URL: "https://www.spaceimpact.io",
  SITE_URL: "http://localhost:8080",
  // API_URL: "http://localhost:5000",
  API_URL: "https://api.autorebalance.co",
  //Network
  TESTNET: true,
  NETWORK_VERSION: 97, //56 mainnet, 97 testnet
  EXPLORER: "testnet.bscscan.com",
  CHAIN: "BSC",
  CHAIN_FULL_NAME: "Binance Smart Chain (BEP20)",
};
