import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import "./assets/native-toast.css";
import "./assets/fontawesome/css/all.min.css";
import "./assets/fonts/index.css";
import "./assets/tailwind.css";

import "v-calendar/dist/style.css";

import VueSocketIO from "vue-3-socket.io";

import VueGoogleMaps from "@fawmi/vue-google-maps";
import { API_URL } from "./config";

import { SetupCalendar } from "v-calendar";

import VCalendar from "v-calendar";

createApp(App)
  .use(store)
  .use(router)
  .use(SetupCalendar, {})
  .use(VueGoogleMaps, {
    load: {
      key: "AIzaSyDc9fqamcRu3iylL46AzH-a-2_vCwFSVp8",
      // language: 'de',
    },
  })
  .use(VCalendar, {})
  .use(
    new VueSocketIO({
      debug: true,
      connection: API_URL,
      vuex: {
        store,
        actionPrefix: "SOCKET_",
        mutationPrefix: "SOCKET_",
      },
    })
  )
  .mount("#app");
