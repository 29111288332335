import api from "@/api/index";

export default {
  login(credentials) {
    return api().post("login", credentials);
  },
  balance(credentials) {
    return api().get("user/balances", credentials);
  },
  transactions(credentials) {
    return api().post("user/transactions/", credentials);
  },
  withdraw(credentials) {
    return api().post("withdraw", credentials);
  },
  withdrawhistory(credentials) {
    return api().post("withdraw/history", credentials);
  },
  deposithistory(credentials) {
    return api().post("deposit/history", credentials);
  },
  // refers(credentials) {
  //   return api().get("user/refers", credentials);
  // },
  // logs(credentials) {
  //   return api().get("user/logs", credentials);
  // },
};
