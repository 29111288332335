import { createRouter, createWebHistory } from "vue-router";
import nativeToast from "native-toast";
import store from "../store";

const routes = [
  {
    path: "/funds",
    component: () => import("../views/auth/funds"),
    meta: { requiresAuth: true },
  },

  {
    path: "/admin/category",
    component: () => import("../views/auth/admin/addCategory"),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/stations",
    component: () => import("../views/auth/admin/Stations"),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/kyc",
    component: () => import("../views/auth/admin/KYC"),
    meta: { requiresAuth: true },
  },
  {
    path: "/maps",
    component: () => import("../views/public/map"),
  },
  {
    path: "/account",
    component: () => import("../views/auth/account"),
    meta: { requiresAuth: true },
  },
  {
    path: "/balance/:currency",
    component: () => import("../views/auth/balance.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/kyc/:address",
    component: () => import("../views/auth/accountKYC.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/selectkyc/:address",
    component: () => import("../views/auth/selectKYC.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/addphone/:address",
    component: () => import("../views/auth/addPhone.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/casino",
    component: () => import("../views/auth/casino"),
    meta: { requiresAuth: true },
  },

  {
    path: "/one_hash",
    component: () => import("../views/auth/bet/one_hash.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/bet",
    component: () => import("../views/auth/bet/bet.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/two_hash",
    component: () => import("../views/auth/bet/two_hash.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/last_hash",
    component: () => import("../views/auth/bet/last_hash.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/",
    component: () => import("../views/public/home.vue"),
  },

  {
    path: "/refer",
    component: () => import("../views/public/refer.vue"),
  },
  {
    path: "/r/:ref",
    component: () => import("../views/public/home.vue"),
  },
  {
    path: "/model/:model",
    component: () => import("../views/public/detail.vue"),
  },
  {
    path: "/booking/:model",
    component: () => import("../views/public/booking.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("../views/public/pagenotfound.vue"),
  },
  {
    path: "/user/:address",
    component: () => import("../views/public/user.vue"),
  },
  {
    path: "/txrelation/:tx_id",
    component: () => import("../views/public/txrelation.vue"),
  },
  {
    path: "/transactions/:address",
    component: () => import("../views/public/transactions.vue"),
  },
  {
    path: "/deposit/:address",
    component: () => import("../views/public/deposit.vue"),
  },
  {
    path: "/withdraw/:address",
    component: () => import("../views/public/withdraw.vue"),
  },
  {
    path: "/bet/:address",
    component: () => import("../views/public/bet.vue"),
  },
  {
    path: "/swap/:symbol",
    component: () => import("../views/public/swap.vue"),
  },
  {
    path: "/ranking/:currency",
    component: () => import("../views/public/ranking.vue"),
  },
  {
    path: "/lastesttransactions",
    component: () => import("../views/public/lastesttransactions.vue"),
  },
  {
    path: "/alldeposit",
    component: () => import("../views/public/alldeposit.vue"),
  },
  {
    path: "/allwithdrawal",
    component: () => import("../views/public/allwithdrawal.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const currentUser = store.getters.isAuthenticated;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) {
    next("/");
    nativeToast({
      message: "Please Connect Metamask.",
      position: "top",
      timeout: 3000,
      type: "error",
    });
  } else if (requiresAuth && currentUser) {
    next();
  } else {
    next();
  }
});

export default router;
